<template>
<div>

  <v-card>
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="banks"
      :search="search"
      sort-by="nombre"
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
    >

      <template v-slot:[`item.date`]="{ item }">
          {{ dateFormat(item.date) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat color="white">

            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-form ref="form2" v-model="valid">
                  <v-autocomplete
                    v-model="place"
                    :items="categories"
                    :rules="editedItem.placeRules"
                    item-text="name"
                    item-value="id"
                    label="Escoge un Lugar"
                    @change="getBanks"
                    clearable
                    rounded
                    solo
                  ></v-autocomplete>
                </v-form>
              </v-col>
            </v-row>

          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="600px" v-if="place">
            <template v-slot:activator="{ on }">
                <v-btn ref="boton2" color="primary" class="mb-2" dark v-on="on">Nuevo Registro</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid">
                    <v-row>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.crew_daily"
                          label="Personal del dia"
                          :rules="editedItem.crew_dailyRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.crew"
                          label="Cuadrilla"
                          :rules="editedItem.crewRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.movements"
                          label="Movimientos"
                          :rules="editedItem.movementsRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.quantity"
                          label="Cantidad"
                          :rules="editedItem.quantityRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.price"
                          label="Precio"
                          :rules="editedItem.priceRules"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="4">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.date"
                              :rules="editedItem.dateRules"
                              label="Fecha"
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.date"
                            no-title
                            @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          solo
                          auto-grow
                          outlined
                          rows="3"
                          v-model="editedItem.comment"
                          label="Nota"
                          :rules="editedItem.commentRules"
                        ></v-textarea>
                      </v-col>

                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="450">
            <v-card>
              <v-card-title>
                <span>Desea eliminar a {{editedItem.name}}?</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-actions>
                <v-btn color="green darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="green darken-1" text @click="eliminar">Aceptar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-icon color="success" small class="mr-2" @click="editItem(item)">fas fa-edit</v-icon>
        <v-icon color="red" small @click="deleteItem(item)">fas fa-trash-alt</v-icon>
      </template>
    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import * as moment from 'moment';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export default {
  name: "expenses",
  data: () => ({
    dialog: false,
    banks: [],
    dialogDelete: false,
    search: "",
    menu: false,
    loading: false,
    titulo: "Movimientos de Cuadrilla",
    headers: [
      { text: "Fecha", align: "left", value: "date" },
      { text: "Personal del Día", align: "left", value: "crew_daily" },
      { text: "Cuadrilla", align: "left", value: "crew" },
      { text: "Movimientos", align: "left", value: "movements" },
      { text: "Cantidad", align: "left", value: "quantity" },
      { text: "Precio", align: "left", value: "price" },
      { text: "Nota", align: "left", value: "comment" },
      { text: "Acciones", value: "action", sortable: false }
    ],
    place: "",
    categories: [],
    editedIndex: -1,

    valid: true,
    currencyMask: createNumberMask({
                    prefix: '',
                    allowDecimal: true,
                    includeThousandsSeparator: true,
                    allowNegative: false,
                  }),
    editedItem: {
      id: "",
      date: new Date().toISOString().substr(0, 10),
      movements: "",
      crew: "",
      crew_daily: "",
      comment: "",
      
      placeRules: [v => !!v || "Escoge un Lugar"],
      movementsRules: [v => !!v || "Escribe un cantidad"],
      dateRules: [v => !!v || "Escoge la fecha del gasto"],
      active: false
    },
    defaultItem: {
      date : new Date().toISOString().substr(0, 10),
      movements: "",
      crew: "",
      crew_daily: "",
      comment: "",

      placeRules: [v => !!v || "Escoge un Lugar"],
      movementsRules: [v => !!v || "Escribe un cantidad"],
      dateRules: [v => !!v || "Escoge la fecha del gasto"],
      active: false
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    ...mapState(["db","headers_db"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions(["cerrarSesion", "validateSession"]),
    // Metodos base de datos
    async getCategories(){
      await axios.get(this.db + 'places',
        {
          headers: this.headers_db
        }).then(response => {
          this.categories = response.data.data;
          this.loading = false;
          console.log(response.data.data);
        })
        .catch((error) => {
          this.validateSession(error).then((resp) => { if(resp) this.getCategories(); })
        });
    },

    async getBanks(){
      console.log(this.place);
      this.banks = [];
      if (!this.place > 0) {
        return;
      }
      
      await axios.get(this.db + 'crews/' + this.place,
        {
          headers: this.headers_db
        }).then(response => {
          this.banks = response.data.data;
          this.loading = false;
          console.log(response.data.data);
        })
        .catch((error) => {
          this.validateSession(error).then((resp) => { if(resp) this.getBanks(); })
        });
    },

    addProcess(payload){
      console.log(payload);
      axios.post(this.db + 'crew/add', payload,
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        this.getBanks();
      })
      .catch((error) => {
        this.validateSession(error).then((resp) => { if(resp) this.addProcess(payload); })
      });

    },
    
    updateProcess(payload){

      axios.post(this.db + 'crew/update', payload,
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response.data.message);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        //this.getBanks();
      })
      .catch((error) => {
        this.validateSession(error).then((resp) => { if(resp) this.updateProcess(payload); })
      });

    },

    deleteProcess(id){

      axios.post(this.db + 'crew/delete', { id },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.banks = this.banks.filter(doc => {
        return doc.id != id
        })
      })
      .catch((error) => {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.deleteProcess(id); })
      });

    },
    // FIN Metodos base de datos


    // Metodos Generales
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY');
    },

    selectOption() {
      if (!this.place) {
        this.$refs.form2.validate();
      }
    },

    editItem(item) {
      console.log(item);

      this.editedIndex = this.banks.indexOf(item);
      this.dialog = true;
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item) {
      console.log(item.id);

      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    close() {
      this.dialog = false;
      this.dialogDelete = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.currencyMask = "";
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.$refs.form.validate() && this.$refs.form2.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.banks[this.editedIndex], this.editedItem);
          this.updateProcess(this.editedItem);
        } else {
          this.editedItem.id_place = this.place;
          this.banks.push(this.editedItem);
          this.addProcess(this.editedItem);
        }
        this.close();
      }
    },

    eliminar() {
      this.deleteProcess(this.editedItem.id);
      this.close();
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

  },

  mounted() {
    this.getCategories();
  },
};
</script>

